import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';

import { AppConfigurationService } from '@services/initializers/app-configuration.service';
import { ILocale } from "@models/api/locale/locale.api.interface";
import { ILocaleWithTotalCount } from "@models/api/locale/locale-with-total-count.api.interface";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class LocaleService {
  private baseUrl: string = '';

  constructor(
    private configService: AppConfigurationService,
    private httpClient: HttpClient)
  {
    this.baseUrl = `${this.configService.apiEndpoint}/${this.configService.apiVersion}/locales`
  }

  /**
   * Get all Locales.
   * @param params: pagination option
   * @returns
   */
  getAllLocales(params?: {
    skip?: number;
    limit?: number;
  }): Observable<ILocaleWithTotalCount> {
    return this
      .httpClient
      .get<ILocale[]>(
        this.baseUrl,
        { params, observe: "response" }
      )
      .pipe(
        switchMap(
          (response: HttpResponse<ILocale[]>) => {
            return of({
              locales: response.body as ILocale[],
              totalLocales: Number(response.headers?.get('X-Total-Count')) ?? 0,
            } as ILocaleWithTotalCount)
          }
        )
      );
  }

}
