import * as LabelActions from '@actions/label.actions';
import * as LanguageActions from '@actions/language.actions';

import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {
  IGetAllLabelsParams,
  IGetAllLabelsPayload
} from '@models/api/label.api.interface';
import {
  ReplaySubject,
  filter,
  take,
  takeUntil
} from 'rxjs';

import { FormAction } from '@models/enum/form-action.enum';
import { IBaseState } from '@states/base.states';
import { ILabel } from '@models/label/label.interface';
import { ILanguage } from '@models/language/language.interface';
import { ITranslation } from '@models/translation/translation.interface';
import { ITranslationDataSource } from '@models/translation/translation-datasource.interface';
import { LabelStateSelectors } from '@reducers/label.reducers';
import { LangStatusFilterOption } from '@models/enum/lang-status.enum';
import { LanguageStateSelectors } from '@reducers/language.reducers';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Pagination } from '@models/enum/pagination.enum';
import { PublishTranslationComponent } from '@components/translation-publishment/translation-publish.component';
import { RegEx } from '@models/enum/regular-expression.enum';
import { Store } from '@ngrx/store';
import { TranslationFormComponent } from '@components/translation-form/translation-form.component';

const TOOLTIP = {
  minWidth: "200px",
  maxWidth: '600px',
  overflowY: 'auto',
  maxHeight: '600px',
  minHeight: '100px',
}

@Component({
  selector: 'lms-translations',
  templateUrl: './translations.page.html',
  styleUrls: ['./translations.page.scss'],
})
export class TranslationsComponent implements OnInit, OnDestroy {
  private unsubscribe$: ReplaySubject<any> = new ReplaySubject(1);

  public labelForm: FormGroup = new FormGroup({});
  public translationForm: FormGroup = new FormGroup({});
  public langList: ILanguage[] = [];
  public dataSource: ITranslationDataSource[] = [];
  public skip: number = Pagination.skip;
  public pageSize: number = Pagination.pageSize;
  public pageIndex: number = Pagination.pageIndexDefault;
  public totalTranslations: number = Pagination.totalCountDefault;
  public searchText: string = '';
  public columnNames: string[] = [];
  public labelId: string = '';
  public rowId: string = '';
  public columnId: string = '';
  public fullLangList: ILanguage[] = [];
  public selectedTypeOfLang: LangStatusFilterOption = LangStatusFilterOption.active;
  public isFilterMenuVisible = false;
  public labelPattern: string = RegEx.LabelFormat;
  public descriptionTp = TOOLTIP;

  public searchForm: FormGroup = new FormGroup({
    searchInput: new FormControl(''),
  });

  public filterForm: FormGroup = new FormGroup({
    radio: new FormControl(''),
  });

  public constructor(
    private modalService: NzModalService,
    private store: Store<IBaseState>,
    private cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
  ) {}

  public ngOnInit(): void {
    this.labelForm = this.fb.group({
      label: ['', [Validators.required, Validators.pattern]],
    });

    this.translationForm = this.fb.group({
      translateValue: ['', [Validators.required]],
    });

    this.filterForm = this.fb.group({
      radio: [LangStatusFilterOption.active],
    });

    this.store.dispatch(new LanguageActions.GetLanguages({}));
    this.languagesSubscriber();
    this.labelSubscriber();
    this.labelsTotalSubscriber()
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public handleSearch(): void {
    this.searchText = (this.searchForm.get('searchInput')?.value as string) || '';
    this.skip = Pagination.skip;
    this.pageIndex = Pagination.pageIndexDefault;
    this.getTranslations();
  }

  public handlePageIndexChanged(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.skip = (this.pageIndex - 1) * this.pageSize;
    this.getTranslations();
  }

  public handlePageSizeChanged(pageSize: number): void {
    this.pageSize = pageSize;
    this.getTranslations();
  }

  public openCreateUpdateForm(
    translation: ITranslationDataSource = {
      id: undefined,
      label: '',
      translations: Object
        .values(this.columnNames)
        .reduce(
          (obj, currLang) => ({
            ...obj,
            [currLang]: '',
          }),
          {},
      ) ?? [],
    },
  ): void {
    const ref = this.modalService.create({
      nzContent: TranslationFormComponent,
      nzComponentParams: {
        mode: translation?.label ? FormAction.update : FormAction.add,
        row: translation,
        filteredLangList: this.langList,
      },
      nzWidth: 1000,
    });

    ref
      .getContentComponent()
      .labelDataEmitter
      .pipe(
        take(1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((labels: ILabel[]): void => {
        this.upsertAndReloadLabel(labels);
      })
  }

  public showPublishTranslationsModal() {
    this.modalService.create({
      nzContent: PublishTranslationComponent,
      nzComponentParams: {
        languages: this.fullLangList,
      },
      nzWidth: 800,
    });
  }

  public getCellValueInRow(lang: ILanguage, row: ITranslationDataSource): string {
    return row.translations[lang.name || '']?.value || '';
  }

  public getId(row: ITranslationDataSource): string[] {
    return [row.id ?? '']
  }

  public startEditLabel(row: ITranslationDataSource): void {
    this.labelForm.patchValue({ label: row.label });
    this.labelId = row.id ?? '';
  }

  public confirmEditLabel(): void {
    if (this.labelForm.valid) {
      let row = this
        .dataSource
        .find((t) => t.id === this.labelId);

      let result: ILabel = {
        id: row?.id ?? '',
        value: this.labelForm.value.label,
        description: row?.description,
        translations: [],
      }

      this.upsertAndReloadLabel([result]);
    }
    this.labelId = '';
    this.handleSearch();
  }

  public startEditTranslation(lang: ILanguage, row: ITranslationDataSource): void {
    this.stopEditing();
    let value: string = this.getCellValueInRow(lang, row);
    this.translationForm.patchValue({ translateValue: value });
    this.rowId = row?.id ?? '';
    this.columnId = lang.id ?? '';
  }

  public confirmEditTranslation(lang: ILanguage): void {
    if (this.translationForm.valid) {
      let row = this
        .dataSource
        .find((t) => t.id === this.rowId) || undefined;
      let translation: ITranslation = {
        id: row?.translations[lang?.name as string]?.id,
        label_id: row?.id ?? '',
        value: this.translationForm.value.translateValue ?? '',
        language: undefined,
        language_id: lang.id,
      }
      let result: ILabel = {
        id: row?.id ?? '',
        value: row?.label ?? '',
        description: row?.description,
        translations: [translation],
      }

      this.upsertAndReloadLabel([result]);
    }

    this.rowId = '';
    this.columnId = '';
  }

  public upsertAndReloadLabel(labels: ILabel[]): void {
    this.store.dispatch(new LabelActions.UpsertLabel({
        labels: labels,
        params: {
          langs: this.langList,
          params: this.getTranslationsParams(),
        }
      }))
  }

  public getErrorMessage(): ValidationErrors | undefined {
    return this.labelForm?.get('label')?.errors || undefined;
  }

  public isFormInvalid(form: FormGroup, controlName: string): boolean {
    return !!(
      form?.get(controlName)?.invalid &&
      (form?.get(controlName)?.dirty || form?.get(controlName)?.touched)
    );
  }

  public startEditNextInput(lang: ILanguage, row: ITranslationDataSource): void {
    this.stopEditing();
    const nextCol = this.langList.indexOf(lang) + 1;
    if (nextCol < this.langList.length) {
      const nextColumn = this.langList[nextCol];
      this.startEditTranslation(nextColumn, row);
    } else {
      // move to edit next label
      const nextRowIndex = this.dataSource.indexOf(row) + 1;
      if (nextRowIndex < this.dataSource.length) {
        const nextRow = this.dataSource[nextRowIndex]
        this.startEditLabel(nextRow);
      }
    }
  }

  public stopEditing(): void {
    this.rowId = '';
    this.columnId = '';
    this.labelId = '';
  }

  public applyLanguageFilter(): void {
    this.langList = this.fullLangList;
    this.selectedTypeOfLang = this.filterForm.get('radio')?.value ?? '';
    this.sortAndReOrderLangList();
    this.isFilterMenuVisible = !this.isFilterMenuVisible;
  }

  private getTranslationsParams(): IGetAllLabelsParams {
    return {
      pagination: {
        skip: this.skip ?? '',
        limit: this.pageSize ?? '',
      },
      contain: this.searchText ?? '',
    };
  }

  private getTranslations(): void {
    const labelsPayload: IGetAllLabelsPayload = {
      langs: this.langList,
      params: this.getTranslationsParams()
    };

    this.store.dispatch(new LabelActions.GetLabels(labelsPayload));
  }

  private languagesSubscriber(): void {
    this.store
      .select(LanguageStateSelectors.languages)
      .pipe(
        filter((langs) => langs.length > 0),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((langs: ILanguage[]) => {
        this.fullLangList = langs;
        this.langList = langs;
        this.sortAndReOrderLangList();
      });
  }

  private labelsTotalSubscriber(): void {
    this.store
      .select(LabelStateSelectors.totalLabels)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((total: number) => {
        this.totalTranslations = total;
      });
  }

  private findTranslationValueByLabelAndLanguage(
    transList: ITranslation[],
    lang: string,
  ): ITranslation | '' {
    return (
      transList
        .find((t) => t?.language?.name === lang) ?? ''
    );
  }

  private labelSubscriber(): void {
    this.store
      .select(LabelStateSelectors.labels)
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe((labels: ILabel[]) => {
        // cast translations to data source
        this.dataSource = labels.map((label) => {
          return {
            id: label.id,
            label: label.value,
            description: label.description ?? '',
            translations: Object.values(this.columnNames).reduce(
                (obj, currLang) => ({
                  ...obj,

                  [currLang]: this.findTranslationValueByLabelAndLanguage(
                    label.translations ?? [],
                    currLang,
                  ),
                }),
                {},
              ),
          }
        }) as ITranslationDataSource[];

        this.cdRef.detectChanges();
      });
  }

  private getColumnName(): string[] {
    return this.langList.map((lang) => lang.name)
  }

  private sortAndReOrderLangList(): void {
    switch (this.selectedTypeOfLang) {
      case LangStatusFilterOption.active:
        this.filterLangListByStatus(LangStatusFilterOption.active);
        break;
      case LangStatusFilterOption.inactive:
        this.filterLangListByStatus(LangStatusFilterOption.inactive);
        break;
    }

    if (!this.langList.length) {
      this.dataSource = [];
      this.totalTranslations = 0;
    } else if (this.langList.length > 1) {
      const defaultLang: ILanguage | undefined = this
        .langList
        .find((lang): boolean => {
          return lang.is_default === true;
      });

      if (defaultLang) {
        this.langList = this
          .langList
          .filter((lang) => lang.is_default === false);
        this.langList.sort((a, b) => Number(a.id) - Number(b.id));
        this.langList.unshift(defaultLang);
      }
      this.store.dispatch(new LabelActions.GetLabels({ langs: this.langList }))
      this.columnNames = this.getColumnName();
    } else {
      this.store.dispatch(new LabelActions.GetLabels({ langs: this.langList }))
      this.columnNames = this.getColumnName();
    }
  }

  private filterLangListByStatus(status: string): void {
    if (status === LangStatusFilterOption.active) {
      this.langList = this
        .langList
        .filter((lang): boolean => {
          return lang.is_active === true;
        });
    } else {
      this.langList = this
        .langList
        .filter((lang): boolean => {
          return lang.is_active === false;
        }) ?? [];
    }
  }
}
