<lms-loader *ngIf="loading$ | async" [size]="'large'"></lms-loader>
<nz-layout>
  <nz-sider
    nzTheme="light"
    [nzCollapsedWidth]="50"
    nzCollapsible
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <span
      class="trigger"
      nz-icon
      [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
      (click)="isCollapsed = !isCollapsed"
    ></span>
    <nz-divider></nz-divider>
    <ul nz-menu nzMode="inline">
      <li
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Language' : ''"
        [routerLink]="['/languages']"
        [routerLinkActive]="['is-active']"
        [nzSelected]="isLanguageSelected"
      >
        <span nz-icon nzType="file-text" nzTheme="outline"></span>
        <span> {{ 'common.label.languages' | translate }} </span>
      </li>
      <li
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Translation' : ''"
        [routerLink]="['/translations']"
        [routerLinkActive]="['is-active']"
        [nzSelected]="isTranslationSelected"
      >
        <span nz-icon nzType="translation" nzTheme="outline"></span>
        <span> {{ 'common.label.translations' | translate }} </span>
      </li>
      <li class="sider-footer">
        <span class="title" [ngStyle]="{ visibility: isCollapsed ? 'hidden' : 'visible' }">
          <a [href]="getBackOfficeUrl()">{{ 'common.btn.toBackOffice' | translate }}</a>
        </span>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-content>
      <lms-header></lms-header>
      <nz-divider></nz-divider>
      <div>
        <lms-breadcrumb></lms-breadcrumb>
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
