<div class="creation-form">
  <form nz-form [formGroup]="labelForm">
    <h1>
      {{
        (mode === 'ADD' ? 'translations.label.addLabel' : 'translations.label.updateLabel')
          | translate
      }}
    </h1>
    <div nz-col [nzSpan]="24" class="form-content">
      <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired>
          {{ 'translations.label.label' | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="18" nzHasFeedback [nzErrorTip]="labelErrTpl">
          <input
            nz-input
            formControlName="label"
            placeholder="Label"
            type="label"
            [pattern]="labelPattern"
          />
          <ng-template #labelErrTpl let-control>
            <ng-container *ngIf="control.hasError('pattern')">
              {{ 'labels.msg.labelPattern' | translate }}
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              {{ 'common.msg.requiredInput' | translate }}
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="4"> {{ 'common.description' | translate }} </nz-form-label>
        <nz-form-control [nzSpan]="18">
          <textarea
            nz-input
            formControlName="description"
            placeholder="{{ 'translations.placeholders.labelDesc' | translate }}"
          ></textarea>
        </nz-form-control>
        <p nz-col [nzSpan]="2" nz-button nzType="link" (click)="showDescriptionPreview()">
          {{ 'translations.label.markdownPreview' | translate }}
        </p>
      </nz-form-item>
      <ng-container *ngFor="let col of columnNames">
        <nz-form-item>
          <nz-form-label [nzSpan]="4" nzRequired>
            {{ col }}
          </nz-form-label>
          <nz-form-control [nzSpan]="18" nzHasFeedback [nzErrorTip]="langErrTpl">
            <textarea
              [formControlName]="col"
              nz-input
              rows="2"
              placeholder="Enter {{ col }} translation here"
            ></textarea>
            <ng-template #langErrTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                {{ 'common.msg.requiredInput' | translate }}
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
      <ng-template [nzModalFooter]>
        <button nz-button nzType="default" (click)="handleCancel()">
          {{ 'common.btn.cancel' | translate }}
        </button>
        <button nz-button nzType="primary" [disabled]="labelForm.invalid" (click)="handleSubmit()">
          {{ 'common.btn.submit' | translate }}
        </button>
      </ng-template>
    </div>
  </form>
</div>
