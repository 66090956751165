import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from "@ngx-translate/core";

import { AppConfigurationService } from "@services/initializers/app-configuration.service";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

export function LocalizationLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export function LocalizationInstanceFactory(
  translate: TranslateService,
  appConfigService: AppConfigurationService,
): () => Promise<void> {
  return async (): Promise<void> => {
    translate.setDefaultLang(appConfigService.defaultLang);
  };
};

export class CustomMissingKeyHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return '';
  }
}
