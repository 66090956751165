import { Action } from '@ngrx/store';
import { ILocaleWithTotalCount } from '@models/api/locale/locale-with-total-count.api.interface';
import { IUser } from '@models/user/user.interface';

export enum ConfigActionTypes {
  // Get User
  GET_USER = 'GET_USER',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_FAILURE = 'GET_USER_FAILURE',

  // Get Locales
  GET_LOCALES = 'GET_LOCALES',
  GET_LOCALES_SUCCESS = 'GET_LOCALES_SUCCESS',
  GET_LOCALES_FAILURE = 'GET_LOCALES_FAILURE',

  // Update Stored App Version
  UPDATE_STORED_VERSION = 'UPDATE_STORED_VERSION',

  // Clear store
  CLEAR_CONFIG_STORE = 'CLEAR_CONFIG_STORE'
}

// Start Get User
export class GetUser implements Action {
  readonly type: ConfigActionTypes = ConfigActionTypes.GET_USER;
  constructor(public payload: any) { }
}

export class GetUserSuccess implements Action {
  readonly type: ConfigActionTypes = ConfigActionTypes.GET_USER_SUCCESS;
  constructor(public payload: IUser) { }
}

export class GetUserFailure implements Action {
  readonly type: ConfigActionTypes = ConfigActionTypes.GET_USER_FAILURE;
  constructor(public payload: any) { }
}

// End Get User

// Start Get Locales
export class GetLocales implements Action {
  readonly type: ConfigActionTypes = ConfigActionTypes.GET_LOCALES;
  constructor(public payload: any = null) { }
}

export class GetLocalesSuccess implements Action {
  readonly type: ConfigActionTypes = ConfigActionTypes.GET_LOCALES_SUCCESS;
  constructor(public payload: ILocaleWithTotalCount) { }
}

export class GetLocalesFailure implements Action {
  readonly type: ConfigActionTypes = ConfigActionTypes.GET_LOCALES_FAILURE;
  constructor(public payload: any) { }
}

export class ClearConfigStore implements Action {
  readonly type: ConfigActionTypes = ConfigActionTypes.CLEAR_CONFIG_STORE;
  constructor(public payload: any = null) { }
}
// End Get Locales

// Start Version

export class UpdateStoredVersion implements Action {
  readonly type: ConfigActionTypes = ConfigActionTypes.UPDATE_STORED_VERSION;
  constructor(public payload: string) { }
}
// End Version

export type ConfigActions =
| GetUser
| GetUserSuccess
| GetUserFailure
| GetLocales
| GetLocalesSuccess
| GetLocalesFailure
| UpdateStoredVersion
| ClearConfigStore
;
