<button
  nz-button
  nz-dropdown
  nzTrigger="click"
  nzSize="large"
  [nzDropdownMenu]="menu"
  [nzPlacement]="'bottomLeft'"
  [nzType]="'primary'"
>
  {{ 'common.btn.upload' | translate }}
</button>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="upload-menu">
    <li class="menu-element">
      <input
        #fileUpload
        class="file-input"
        type="file"
        (change)="onFileSelected($event)"
        (click)="fileUpload.value = ''">
      <div class="file-upload" (click)="fileUpload.click()">
        <span nz-icon nzType="upload"></span>
        {{ 'common.btn.import' | translate }}
      </div>
    </li>
    <li nz-menu-item (click)="exportAsCsv()">
      <span
        ><span nz-icon nzType="download"></span>
        {{ 'translations.btn.exportAsCsv' | translate }}</span
      >
    </li>
    <li nz-menu-item (click)="exportAsJson()">
      <span
        ><span nz-icon nzType="download"></span>
        {{ 'translations.btn.exportAsJson' | translate }}</span
      >
    </li>
  </ul>
</nz-dropdown-menu>
