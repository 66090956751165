import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDeleteLabelPayload, IExportTranslationsPayload, IGetAllLabelsPayload, IImportTranslationsPayload } from '@models/api/label.api.interface';
import { Observable, of, switchMap } from 'rxjs';

import { AppConfigurationService } from '@services/initializers/app-configuration.service';
import { ILabel } from '@models/label/label.interface';
import { ILabelWithTotalCount } from '@models/label/label-with-total-count.interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  private baseUrl: string = '';
  private translationBaseUrl: string = '';
  private labelBaseUrl: string = '';

  constructor(
    private configService: AppConfigurationService,
    private httpClient: HttpClient
  ) {
    this.baseUrl = `${this.configService.apiEndpoint}/${this.configService.apiVersion}`;
    this.translationBaseUrl = `${this.baseUrl}/translations`;
    this.labelBaseUrl = `${this.baseUrl}/labels`;
  }

  /**
   * Get all labels.
  */
  getAllLabels(
    payload: IGetAllLabelsPayload,
  ): Observable<ILabelWithTotalCount> {
    const params = {
      languageIds: payload?.langs?.map((lang) => lang.id).toString() ?? '',
      skip: payload.params?.pagination?.skip ?? '',
      limit: payload.params?.pagination?.limit ?? '',
      contain: payload.params?.contain ?? '',
    };

    return this
      .httpClient
      .get<ILabel[]>(this.labelBaseUrl, { params, observe: 'response' })
      .pipe(
        switchMap((response: HttpResponse<ILabel[]>) => {
          return of({
            labels: response.body as ILabel[],
            totalLabels: Number(response.headers?.get('X-Total-Count')) ?? 0,
          });
        }),
    );
  }

  /**
   * Upsert Labels
   * @param labels
   * @returns
   */
  upsertLabels(label: ILabel[]): Observable<ILabel> {
    return this.httpClient
      .put<ILabel>(this.labelBaseUrl, label)
      .pipe(switchMap((response: ILabel) => of(response)));
  }

  /**
   * Delete Label with provided id.
   * @param id
   * @returns
   */
  deleteLabel(
    payload: IDeleteLabelPayload,
  ): Observable<ILabel> {
    const params = {
      ids: payload.ids
    }
    return this
      .httpClient
      .delete<ILabel>(this.labelBaseUrl, { params })
      .pipe(
        switchMap(
          (response: ILabel) => of(response)
        )
      );
  }

  /**
   * Export translations to csv or json.
   * @param payload
   * @returns
   */
  exportTranslations(payload: IExportTranslationsPayload): Observable<any> {
    const params = {
      type: payload.type ?? '',
    };

    return this.httpClient
      .get(this.translationBaseUrl.concat('/export'), { responseType: 'blob', params })
      .pipe(switchMap((response: any) => of(response)));
  }

  /**
   * Import translations by csv or json.
   * @returns
   */
  importTranslations(payload: IImportTranslationsPayload): Observable<any> {
    const params = {
      type: payload.type ?? '',
    };

    return this.httpClient
      .post(this.translationBaseUrl.concat('/import'), payload.translations, { params })
      .pipe(switchMap((response: any) => of(response)));
  }

  /**
   * Publish Translations
   * @param ids
   */
  publishTranslations(ids: string[]): Observable<any> {
    return this
      .httpClient
      .get(
        `${this.baseUrl}/publish`,
        {params: {ids: ids}}
      );
  }

}
