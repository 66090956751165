<nz-result
  nzStatus="error"
  [nzTitle]=" 'common.label.notAllowed' | translate "
  [nzSubTitle]=" 'common.msg.notAllowed' | translate ">
  <div nz-result-extra>
    <button
      nz-button
      (click)="getBackOfficeUrl()"
      nzType="primary">
        {{ 'common.btn.toBackOffice' | translate }}
    </button>
  </div>
</nz-result>
