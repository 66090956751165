import * as LanguageActions from '@actions/language.actions';

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ReplaySubject, filter, takeUntil } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { ConfigStateSelectors } from "@reducers/config.reducers";
import { FormAction } from '@models/enum/form-action.enum';
import { IBaseState } from "@states/base.states";
import { ILanguage } from "@models/language/language.interface";
import { ILocale } from '@models/api/locale/locale.api.interface';
import { ILocaleDataSource } from "@models/locale/locale-datasource.interface";
import { LanguageStateSelectors } from "@reducers/language.reducers";
import { NzModalRef } from "ng-zorro-antd/modal";
import { Store } from "@ngrx/store";

@Component({
  selector: 'lms-language-form',
  templateUrl: './language-form.component.html',
  styleUrls: ['./language-form.component.scss']
})

export class LanguageFormComponent implements OnInit, OnDestroy {
  private unsubscribe$: ReplaySubject<any> = new ReplaySubject(1);
  private defaultLanguage?: ILanguage;
  public languageList: ILanguage[] = [];
  public localeOptions: ILocale[] = [];
  public languageForm!: UntypedFormGroup;
  public isDefaultCheckBoxTicked: boolean = false;
  public isDefaultLanguage: boolean = false;
  public localeOptionsDataSource: ILocaleDataSource[] = []

  @Input() mode?: FormAction.add | FormAction.update = FormAction.add;
  @Input() language?: ILanguage;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<IBaseState>,
    private modalRef: NzModalRef,
  ) { }

  public ngOnInit(): void {
    this.languagesSubscriber();
    this.localesSubscriber();
    this.languageForm = this.fb.group({
      locale: [
        this
          .localeOptions
          ?.find((locale): boolean => {
            return locale.value === this
              .language
              ?.locale
          })?.value ?? '', [Validators.required]],
      isDefault: [
        this
          .language
          ?.is_default],
      isActive: [
        this
          .language
          ?.is_active],
    });
    this.isDefaultLanguage = this
      .language
      ?.is_default ?? false;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public validateFormAndSubmit(): boolean {
    if (this.languageForm.valid) {
      const payload: ILanguage = {
        id: this.language?.id ?? undefined,
        name: this
          .localeOptions
          .find((locale): boolean => {
            return locale.value === this
              .languageForm
              .value
              .locale
        })?.name ?? '',
        locale: this
          .localeOptions
          .find((locale): boolean => {
            return locale.value === this
              .languageForm
              .value
              .locale
        })?.value,
        is_default: this
          .languageForm
          .value
          .isDefault,
        is_active: this
          .languageForm
          .value
          .isActive ?? false,
      }

      this.store.dispatch(new LanguageActions.UpsertLanguage(payload))
    } else {
      Object
        .values(this.languageForm.controls)
        .forEach(control => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
    }

    return this.languageForm.valid;
  }

  public defaultChange(value: boolean): void {
    if (this.isDefaultLanguage) {
      this
        .languageForm
        .controls['isDefault']
        .addValidators([Validators.requiredTrue])
    } else {
      this
        .languageForm
        .controls['isDefault']
        .removeValidators([Validators.requiredTrue])

      this.isDefaultCheckBoxTicked = value;
    }

    if (this.languageForm.value.isDefault) {
      this
        .languageForm
        .get('isActive')
        ?.setValue(true)
    }

    this
      .languageForm
      .controls['isDefault']
      .updateValueAndValidity();
  }

  public getDefaultLanguageName(): string {
    this.defaultLanguage = this
      .languageList
      .find((lang: ILanguage) => {
        return lang.is_default === true;
    })

    return this.defaultLanguage?.name || '';
  }

  public handleSubmit(): void {
    if(this.validateFormAndSubmit()) {
      this.modalRef.destroy()
    }
  }

  public handleCancel(): void {
    this.modalRef.destroy()
  }

  private localesSubscriber(): void {
    this.store.select(ConfigStateSelectors.locales)
      .pipe(
        filter(langs => langs.length > 0),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((locales: ILocale[]) => {
        this.localeOptions = locales;
        this.castLocalesToDataSource();
      });
  }

  private languagesSubscriber(): void {
    this.store.select(LanguageStateSelectors.languages)
      .pipe(
        filter(langs => langs.length > 0),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((langs: ILanguage[]) => {
        this.languageList = langs
      });
  }

  private castLocalesToDataSource(): void {
    this.localeOptionsDataSource = this
      .localeOptions
      .map((locale) => {
        return {
          label: locale.name + ` (${locale.value})`,
          value: locale.value,
          disable: this
            .languageList
            .some((lang: ILanguage): boolean => {
              return lang?.locale === locale.value;
            }) ?? false,
        } as ILocaleDataSource
      })
  }
}
