import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject, filter, takeUntil } from 'rxjs';

import { ConfigStateSelectors } from "@reducers/config.reducers";
import { IBaseState } from '@states/base.states';
import { IUser } from '@models/user/user.interface';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';

@Component({
  selector: 'lms-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private unsubscribe$: ReplaySubject<any> = new ReplaySubject(1);

  public user?: IUser;

  constructor(
    private store: Store<IBaseState>,
    private cdRef: ChangeDetectorRef,
    private msalService: MsalService) {}

  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public ngOnInit(): void {
    this.userSubscriber();
  }

  public logout(): void {
    this.msalService.logout();
  }

  private userSubscriber(): void {
    this.store.select(ConfigStateSelectors.user)
      .pipe(
        filter(user => user !== undefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((user?: IUser) => {
        this.user = user;
        this.cdRef.detectChanges();
      });
  }
}
