import { HttpErrorResponse } from '@angular/common/http';


export const ParseHttpErrorRes = (res: HttpErrorResponse): string => {
  if (res.status != 0 && res.error) {

    return res.error.message;
  }

  return res.statusText;
}
