import { AppConfigurationService } from '@services/initializers/app-configuration.service';
import { Component } from '@angular/core';

@Component({
  selector: 'lms-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  constructor(private configService: AppConfigurationService) { }

  getBackOfficeUrl(): string {
    return this.configService.backOfficeRedirectUrl;
  }
}
