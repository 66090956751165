export const environment = {
  production: false,
  defaultLang: 'en',
  version: '1.0.1',
  apiEndpoint: 'https://dev-api.rejsebillet.dk/lmsapi',
  backOfficeRedirectUrl: 'https://dev-backoffice.rejsebillet.dk/',
  apiVersion: 'v1',
  apimSubscriptionKey: "1fbe49cb0a6d43c8b8128c2f747c5ec9",
  sessionTimeOut: 1800000,
  authStrategy: {
    clientId: 'ded2b788-63e1-4bc8-84b2-216c77f26851',
    authority: 'https://login.microsoftonline.com/8fcc742e-30fd-4cd8-a1a6-a1b36ab4231e',
    redirectUri: 'https://dev-lms.rejsebillet.dk',
    scopes: ['ded2b788-63e1-4bc8-84b2-216c77f26851/Api.Groups']
  },
};
