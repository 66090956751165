import * as ConfigReducers from '@reducers/config.reducers';
import * as LabelReducers from '@reducers/label.reducers';
import * as LanguageReducers from '@reducers/language.reducers';

import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';

import { CommonModule } from '@angular/common';
import { ConfigEffects } from '@effects/config.effects';
import { EffectsModule } from '@ngrx/effects';
import { IBaseState } from '@states/base.states';
import { LabelEffects } from '@effects/label.effects';
import { LanguageEffects } from '@effects/language.effects';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { localStorageSync } from 'ngrx-store-localstorage';

export const Reducers: ActionReducerMap<IBaseState, any> = {
  languages: LanguageReducers.reducer,
  config: ConfigReducers.reducer,
  labels: LabelReducers.reducer
};

const EFFECTS_LIST: Array<any> = [
  LanguageEffects,
  ConfigEffects,
  LabelEffects,
];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      {
        config: ['version'],
      },
    ],
    rehydrate: true
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(Reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      name: 'LMS Store',
      maxAge: 100,
      logOnly: !environment.production,
    }),

    EffectsModule.forRoot(EFFECTS_LIST),
  ]
})
export class RootStoreModule { }
