import * as ConfigActions from '@actions/config.actions';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { ReplaySubject, filter, takeUntil } from 'rxjs';

import { IBaseState } from '@states/base.states';
import { InteractionStatus } from '@azure/msal-browser';
import { Store } from '@ngrx/store';

@Component({
  selector: 'lms-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$: ReplaySubject<any> = new ReplaySubject(1);

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private readonly store: Store<IBaseState>) { }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  public ngOnInit() {
    // refresh user
    this
      .msalBroadcastService
      .inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.refreshAuthUser();
      });
  }

  private refreshAuthUser() {
    /**
   * If no active account set but there are accounts signed in, sets first account to active account
   * To use active account set here, subscribe to inProgress$ first in your component
   * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
   */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      activeAccount = accounts[0];
      this.msalService.instance.setActiveAccount(activeAccount);
    }

    if (activeAccount) {
      this.store.dispatch(new ConfigActions.GetUserSuccess({ email: activeAccount?.username }));
    }
  }
}
