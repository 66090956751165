import { LabelActionTypes, LabelActions } from '@actions/label.actions';

import { IBaseState } from '@states/base.states';
import { ILabelsState } from "@states/label.states";
import { createSelector } from '@ngrx/store';

const initialState: ILabelsState = {
  labels: [],
  totalLabels: 0,
  loading: [],
}

export function reducer(state: ILabelsState = initialState, action: LabelActions): ILabelsState {
  switch (action.type) {
    // START Get Labels
    case LabelActionTypes.GET_LABELS: {
      return {
        ...state,
        loading: [...state.loading, action.type]
      };
    }

    case LabelActionTypes.GET_LABELS_SUCCESS: {
      return {
        ...state,
        labels: action.payload.labels,
        totalLabels: action.payload.totalLabels,
        loading: clearLoading(state, LabelActionTypes.GET_LABELS),
      };
    }

    case LabelActionTypes.GET_LABELS_FAILURE: {
      return {
        ...state,
        labels: [],
        totalLabels: 0,
        loading: clearLoading(state, LabelActionTypes.GET_LABELS),
      };
    }
    // END Get Labels

    // Start Upsert Labels
    case LabelActionTypes.UPSERT_LABEL: {
      return {
        ...state,
        loading: [...state.loading, action.type]
      };
    }

    case LabelActionTypes.UPSERT_LABEL_COMPLETE: {
      return {
        ...state,
        loading: clearLoading(state, LabelActionTypes.UPSERT_LABEL),
      };
    }
    // End Upsert Labels

    // Start Publish Labels
    case LabelActionTypes.PUBLISH_LABELS: {
      return {
        ...state,
        loading: [...state.loading, action.type]
      };
    }

    case LabelActionTypes.PUBLISH_LABELS_COMPLETE: {
      return {
        ...state,
        loading: clearLoading(state, LabelActionTypes.PUBLISH_LABELS),
      };
    }
    // End Publish Labels

    // Start Import Labels
    case LabelActionTypes.IMPORT_LABELS: {
      return {
        ...state,
        loading: [...state.loading, action.type]
      };
    }

    case LabelActionTypes.IMPORT_LABELS_COMPLETE: {
      return {
        ...state,
        loading: clearLoading(state, LabelActionTypes.IMPORT_LABELS),
      };
    }
    // End Import Labels

    // Start Export Labels
    case LabelActionTypes.EXPORT_LABELS: {
      return {
        ...state,
        loading: [...state.loading, action.type]
      };
    }

    case LabelActionTypes.EXPORT_LABELS_COMPLETE: {
      return {
        ...state,
        loading: clearLoading(state, LabelActionTypes.EXPORT_LABELS),
      };
    }
    // End Export Labels

    case LabelActionTypes.CLEAR_LABEL_STORE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}


function clearLoading(state: ILabelsState, action: LabelActionTypes): Array<LabelActionTypes> {
  return state.loading.filter((loadingMarker: LabelActionTypes) => loadingMarker !== action);
}

const stateSelector = (state: IBaseState) => state.labels;

export const LabelStateSelectors = {
  labels: createSelector(stateSelector, (state: ILabelsState) => state.labels),
  getLoading: createSelector(stateSelector, (state: ILabelsState) => state.loading),
  totalLabels: createSelector(stateSelector, (state: ILabelsState) => state.totalLabels),
};
