import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDeleteLanguagePayload, IGetAllLanguagesPayload } from '@models/api/language.api.interface';
import { Observable, of, switchMap } from 'rxjs';

import { AppConfigurationService } from '@services/initializers/app-configuration.service';
import { ILanguage } from '@models/language/language.interface';
import { ILanguagesWithTotalCount } from '@models/language/language-with-total-count.interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class LanguageService {
  private baseUrl: string = '';

  constructor(
    private configService: AppConfigurationService,
    private httpClient: HttpClient)
  {
    this.baseUrl = `${this.configService.apiEndpoint}/${this.configService.apiVersion}/languages`
  }

  /**
   * Get all Languages.
   * @param params: pagination option
   * @returns
   */
  getAllLanguages(
    langParams?: IGetAllLanguagesPayload
  ): Observable<ILanguagesWithTotalCount> {
    const params = {
      skip: langParams?.params?.pagination?.skip ?? '',
      limit: langParams?.params?.pagination?.limit ?? '',
      contain: langParams?.params?.contain ?? '',
      isActive: langParams?.params?.isActive ?? '',
    };
    return this
      .httpClient
      .get<ILanguage[]>(
        this.baseUrl,
        { params, observe: "response" }
      )
      .pipe(
        switchMap(
          (response: HttpResponse<ILanguage[]>) => {
            return of({
              languages: response.body as ILanguage[] ?? [],
              totalLanguages: Number(response.headers?.get('X-Total-Count')) ?? 0,
            } as ILanguagesWithTotalCount)
          }
        )
      );
  }

  /**
 * Upsert Language
 * @param language
 * @returns
 */
  upsertLanguage(language: ILanguage): Observable<ILanguage> {
    return this
      .httpClient
      .put<ILanguage>(this.baseUrl, language)
      .pipe(
        switchMap(
          (response: ILanguage) => of(response)
        )
      );
  }

  /**
   * Delete language with provided id.
   * @param id
   * @returns
   */
  deleteLanguage(
    payload: IDeleteLanguagePayload,
  ): Observable<ILanguage> {
    return this
      .httpClient
      .delete<ILanguage>(`${this.baseUrl}/${payload.id}`)
      .pipe(
        switchMap(
          (response: ILanguage) => of(response)
        )
      );
  }

}
