<button
  nz-popconfirm
  [nzOkText]="'common.btn.ok' | translate"
  [nzCancelText]="'common.btn.cancel' | translate"
  [nzPopconfirmTitle]="'common.msg.confirmDelete' | translate"
  nzShape="circle"
  (nzOnConfirm)="confirm()"
  nz-button
  nzType="default"
>
  <span nz-icon nzType="delete" nzTheme="outline"></span>
</button>
