import { AppConfigurationService } from '@services/initializers/app-configuration.service';
import { Component } from '@angular/core';

@Component({
  selector: 'lms-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  constructor(
    private configService: AppConfigurationService,
  ) {}

  public getBackOfficeUrl(): void {
    window.location.assign(this.configService.backOfficeRedirectUrl);
  }
}
