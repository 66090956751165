<div class="bg-overlay">
  <div class="spinner-container" [ngClass]="getSizeClass()">
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
  </div>
  <ng-container *ngIf="message">
    <div class="loading-msg">{{ message | translate }}</div>
  </ng-container>
</div>
