import {NgModule} from '@angular/core';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzNotificationModule} from 'ng-zorro-antd/notification';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {NzPopconfirmModule} from 'ng-zorro-antd/popconfirm';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzUploadModule} from 'ng-zorro-antd/upload';

@NgModule({
  exports: [
    NzButtonModule,
    NzTableModule,
    NzInputModule,
    NzGridModule,
    NzIconModule,
    NzPageHeaderModule,
    NzLayoutModule,
    NzBreadCrumbModule,
    NzNotificationModule,
    NzPaginationModule,
    NzModalModule,
    NzPopconfirmModule,
    NzFormModule,
    NzDividerModule,
    NzCardModule,
    NzSelectModule,
    NzMenuModule,
    NzToolTipModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzUploadModule,
    NzRadioModule,
    NzResultModule
  ],
})

export class AppAntdComponentsModule { }
