import * as LabelActions from '@actions/label.actions';
import * as LanguageActions from '@actions/language.actions';

import { Component, Input } from '@angular/core';

import { IBaseState } from '@states/base.states';
import { ILanguage } from '@models/language/language.interface';
import { NotificationService } from '@services/utils/notification.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'lms-popup-confirm',
  templateUrl: './popup-confirm.component.html',
  styleUrls: ['./popup-confirm.component.scss'],
})
export class PopupConfirmComponent {
  @Input() labelIds: string[] = [];
  @Input() language: ILanguage | undefined = undefined;
  @Input() isDeleteTranslations: boolean = false;
  @Input() isDeleteLanguage: boolean = false;

  constructor(
    private store: Store<IBaseState>,
    private notificationService: NotificationService) {}

  public confirm(): void {
    if (this.isDeleteLanguage && this.language?.id) {
      if (!this.language?.is_default) {
        this
          .store
          .dispatch(new LanguageActions.DeleteLanguage({
            id: this.language?.id
          }));
      } else {
        this
          .notificationService
          .error('common.msg.notAllowedDeleteDefaultLanguage');
      }
    } else {
      this
        .store
        .dispatch(new LabelActions.DeleteLabel({
          ids: this.labelIds
        }));
    }
  }
}
