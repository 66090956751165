import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ReplaySubject, filter, take, takeUntil } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FormAction } from '@models/enum/form-action.enum';
import { IBaseState } from '@states/base.states';
import { ILabel } from '@models/label/label.interface';
import { ILanguage } from '@models/language/language.interface';
import { ITranslation } from '@models/translation/translation.interface';
import { ITranslationDataSource } from '@models/translation/translation-datasource.interface';
import { LanguageStateSelectors } from "@reducers/language.reducers";
import { MarkdownComponent } from '@components/markdown/markdown.component';
import { RegEx } from '@models/enum/regular-expression.enum';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lms-translation-form',
  templateUrl: './translation-form.component.html',
  styleUrls: ['./translation-form.component.scss']
})

export class TranslationFormComponent implements OnInit, OnDestroy{
  private unsubscribe$: ReplaySubject<any> = new ReplaySubject(1);
  private activeLangList: ILanguage[] = [];
  public columnNames: string[] = [];
  public labelForm!: UntypedFormGroup;
  public labelPattern: string = RegEx.LabelFormat;

  @Input() mode?: FormAction.add | FormAction.update = FormAction.add;
  @Input() row?: ITranslationDataSource;
  @Input() filteredLangList: ILanguage[] = [];
  @Output() labelDataEmitter = new EventEmitter<ILabel[]>();

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<IBaseState>,
    private modalRef: NzModalRef,
    private modalService: NzModalService,
    private translateService: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.languagesSubscriber();
    this.initFormValue();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public showDescriptionPreview() {
    this.modalService.create({
      nzTitle: this
        .translateService
        .instant('translations.label.markdownPreview') as string,
      nzContent: MarkdownComponent,
      nzComponentParams: {
        content: this.labelForm?.get('description')?.value ?? ''
      },
      nzFooter: null,
      nzWidth: 800,
      nzBodyStyle: {overflowY: `auto`, maxHeight: 'calc(100vh - 200px)', minHeight: `50vh`}
    });
  }

  private languagesSubscriber(): void {
    this.store.select(LanguageStateSelectors.languages)
      .pipe(
        filter(langs => langs.length > 0),
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((langs: ILanguage[]) => {
        this.activeLangList = langs.filter((lang) => lang.is_active === true);
      });
  }

  public validateFormAndSubmit(): boolean {
    if (this.labelForm.valid) {
      let result: ILabel
      if (this.mode === FormAction.add) {
        result = this.upsertLabelByLangList(this.activeLangList);
      } else {
        result = this.upsertLabelByLangList(this.filteredLangList);
      }

      this.labelDataEmitter.emit([result]);
    } else {
      Object
        .values(this.labelForm.controls)
        .forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }

    return this.labelForm.valid;
  }

  public handleSubmit(): void {
    if(this.validateFormAndSubmit()) {
      this.modalRef.destroy()
    }
  }

  public handleCancel(): void {
    this.modalRef.destroy()
  }

  private initFormValue(): void {
    this.initColumnsByLang();

    let dynamicLabelObj = Object
      .values(this.columnNames)
      .reduce(
        (obj, name) => ({
          ...obj,
          label: [this
            .row
            ?.label,
            [
              Validators.required,
              Validators.pattern,
            ]],
          description: [this.row?.description ?? ''],
          [name]: [this
            .row
            ?.translations[name]
            ?.value, [Validators.required]]
        }), {});

    this.labelForm = this.fb.group({...dynamicLabelObj});
  }

  private initColumnsByLang(): void {
    if (this.mode === FormAction.add) {
      this.columnNames = this
        .activeLangList
        .map((lang) => lang.name);
    } else {
      this.columnNames = this
        .filteredLangList
        .map((lang) => lang.name);
    }
  }

  private upsertLabelByLangList(list: ILanguage[]): ILabel {
    let translations: ITranslation[] = list.map((lang) => {
      return {
        id: this.row?.translations[lang.name as string]?.id,
        label_id: this.row?.id ?? undefined,
        value: this.labelForm.value[lang.name as string] ?? '',
        language_id: lang.id,
        language: undefined,
      }
    }) ?? [];

    return {
      id: this.row?.id ?? undefined,
      description: this.labelForm.value.description ?? '',
      value: this.labelForm.value.label ?? '',
      translations: translations ?? [],
    }
  }
}
