import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";

import { AppConfigurationService } from "@services/initializers/app-configuration.service";

export function MsalGuardConfigFactory(appConfigService: AppConfigurationService): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['profile', ...appConfigService.authStrategy.scopes]
    },
    loginFailedRoute: appConfigService.backOfficeRedirectUrl
  };
}

export function MsalInterceptorConfigFactory(appConfigService: AppConfigurationService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>(
    [
      // required access token to all api and lmsapi
      ['api', ['profile', ...appConfigService.authStrategy.scopes]],
      ['lmsapi', ['profile', ...appConfigService.authStrategy.scopes]],
      // not protect assets files to avoid bug where MSAL prevent the files of being load, https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2515
      ['assets', null],
    ]
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MsalInstanceFactory(appConfigService: AppConfigurationService): PublicClientApplication {
  const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

  return new PublicClientApplication({
      auth: {
        clientId: appConfigService.authStrategy.clientId,
        authority: appConfigService.authStrategy.authority,
        redirectUri: appConfigService.authStrategy.redirectUri,
        knownAuthorities: ["login.microsoftonline.com"],
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    });
};
