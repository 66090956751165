import { Component } from '@angular/core';

@Component({
  selector: 'lms-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotFoundComponent {
  constructor() {}

  public goToMainPage(): void {
    window.location.assign('/');
  }
}
