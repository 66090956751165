<ng-template [nzModalTitle]>
  {{ 'translations.title.publish' | translate }}
</ng-template>
<nz-table #basicTable [nzData]="languages" [nzFrontPagination]="false" [nzScroll]="{ y: '450px' }">
  <thead>
    <tr>
      <th>{{ 'languages.label.language' | translate }}</th>
      <th [(nzChecked)]="allChecked" (nzCheckedChange)="checkAll($event)"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let lang of languagesWithCheck">
      <td>{{ lang.language.name }}</td>
      <td [(nzChecked)]="lang.checked" (nzCheckedChange)="onItemChecked()"></td>
    </tr>
  </tbody>
</nz-table>
<ng-template [nzModalFooter]>
  <button nz-button nzType="default" (click)="close()">
    {{ 'common.btn.cancel' | translate }}
  </button>
  <button nz-button nzType="primary" (click)="submit()">
    {{ 'common.btn.submit' | translate }}
  </button>
</ng-template>
