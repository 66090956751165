import { LanguageActionTypes, LanguageActions } from '@actions/language.actions';

import { IBaseState } from '@states/base.states';
import { ILanguagesState } from "@states/language.states";
import { createSelector } from '@ngrx/store';

const initialState: ILanguagesState = {
  languages: [],
  totalLanguages: 0,
  loading: [],
}

export function reducer(state: ILanguagesState = initialState, action: LanguageActions): ILanguagesState {
  switch (action.type) {
    // START Get Languages
    case LanguageActionTypes.GET_LANGUAGES: {
      return {
        ...state,
        loading: [...state.loading, action.type]
      };
    }

    case LanguageActionTypes.GET_LANGUAGES_SUCCESS: {
      return {
        ...state,
        languages: action.payload.languages,
        totalLanguages: action.payload.totalLanguages,
        loading: clearLoading(state, LanguageActionTypes.GET_LANGUAGES),
      };
    }

    case LanguageActionTypes.GET_LANGUAGES_FAILURE: {
      return {
        ...state,
        languages: [],
        totalLanguages: 0,
        loading: clearLoading(state, LanguageActionTypes.GET_LANGUAGES),
      };
    }
    // END Get Languages

    // Start Upsert Languages
    case LanguageActionTypes.UPSERT_LANGUAGE: {
      return {
        ...state,
        loading: [...state.loading, action.type]
      };
    }

    case LanguageActionTypes.UPSERT_LANGUAGE_COMPLETE: {
      return {
        ...state,
        loading: clearLoading(state, LanguageActionTypes.UPSERT_LANGUAGE),
      };
    }
    // End Upsert Languages

    // Reset Language Store
    case LanguageActionTypes.CLEAR_LANGUAGE_STORE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}


function clearLoading(state: ILanguagesState, action: LanguageActionTypes): Array<LanguageActionTypes> {
  return state.loading.filter((loadingMarker: LanguageActionTypes) => loadingMarker !== action);
}

const stateSelector = (state: IBaseState) => state.languages;

export const LanguageStateSelectors = {
  languages: createSelector(stateSelector, (state: ILanguagesState) => state.languages),
  getLoading: createSelector(stateSelector, (state: ILanguagesState) => state.loading),
  totalLanguages: createSelector(stateSelector, (state: ILanguagesState) => state.totalLanguages),
};
