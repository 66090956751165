import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  CustomMissingKeyHandler,
  LocalizationInstanceFactory,
  LocalizationLoaderFactory
} from '@factories/localization.factory';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import {
  MsalGuardConfigFactory,
  MsalInstanceFactory,
  MsalInterceptorConfigFactory
} from '@factories/msal.factory';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';

import { AppAntdComponentsModule } from '@modules/app-antd-components.module';
import { AppComponent } from './app.component';
import { AppConfigurationService } from '@services/initializers/app-configuration.service';
import { AppInterceptor } from './app.interceptor';
import { AppRoutingModule } from '@modules/app-routing.module';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorComponent } from '@pages/error/error.component';
import { ErrorLayoutComponent } from '@components/error-layout/error-layout.component';
import { HeaderComponent } from '@components/header/header.component';
import { LanguageFormComponent } from '@components/language-form/language-form.component';
import { LanguagesComponent } from '@pages/languages/languages.page';
import { LayoutComponent } from '@components/layout/layout.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { MarkdownComponent } from '@components/markdown/markdown.component';
import { MarkdownModule } from 'ngx-markdown';
import { MenuComponent } from '@components/menu/menu.component';
import { NotFoundComponent } from '@pages/notfound/notfound.component';
import { NotificationService } from '@services/utils/notification.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PopupConfirmComponent } from '@components/popup-confirm/popup-confirm.component';
import { PublishTranslationComponent } from '@components/translation-publishment/translation-publish.component';
import { RootStoreModule } from './stores/root-store.module';
import { TranslationFormComponent } from '@components/translation-form/translation-form.component';
import { TranslationsComponent } from '@pages/translations/translations.page';
import { UploadComponent } from '@components/upload/upload.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    MenuComponent,
    HeaderComponent,
    BreadcrumbComponent,
    LayoutComponent,
    LanguagesComponent,
    LanguageFormComponent,
    TranslationsComponent,
    TranslationFormComponent,
    PublishTranslationComponent,
    PopupConfirmComponent,
    UploadComponent,
    MarkdownComponent,
    ErrorLayoutComponent,
    ErrorComponent,
    NotFoundComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    RootStoreModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppAntdComponentsModule,
    BrowserAnimationsModule,
    MarkdownModule.forRoot({ loader: HttpClientModule}),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingKeyHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: LocalizationLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    MsalModule
  ],
  providers: [
    AppConfigurationService,
    NotificationService,
    TranslateService,
    NzMessageService,
    MsalService,
    {
      provide: NZ_I18N,
      useValue: en_US
    },
    {
      provide: APP_INITIALIZER,
      useFactory: LocalizationInstanceFactory,
      multi: true,
      deps: [TranslateService, AppConfigurationService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory,
      deps: [AppConfigurationService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigFactory,
      deps: [AppConfigurationService]
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MsalInstanceFactory,
      deps: [AppConfigurationService]
    },
  ],
  exports: [FormsModule, ReactiveFormsModule],
  bootstrap: [MsalRedirectComponent, AppComponent]
})
export class AppModule {}
