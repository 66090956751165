import { IDeleteLanguagePayload, IGetAllLanguagesParams, IGetAllLanguagesPayload } from '@models/api/language.api.interface';

import { Action } from '@ngrx/store';
import { ILanguage } from '@models/language/language.interface';
import { ILanguagesWithTotalCount } from '@models/language/language-with-total-count.interface';

export enum LanguageActionTypes {
  // Get Languages
  GET_LANGUAGES = 'GET_LANGUAGES',
  GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS',
  GET_LANGUAGES_FAILURE = 'GET_LANGUAGES_FAILURE',

  // Upsert Language
  UPSERT_LANGUAGE = 'UPSERT_LANGUAGE',
  UPSERT_LANGUAGE_COMPLETE = 'UPSERT_LANGUAGE_COMPLETE',

  // Delete Language
  DELETE_LANGUAGE = 'DELETE_LANGUAGE',
  DELETE_LANGUAGE_COMPLETE = 'DELETE_LANGUAGE_COMPLETE',

  // Clear Language Store
  CLEAR_LANGUAGE_STORE = 'CLEAR_LANGUAGE_STORE',
}

// Start Get Languages
export class GetLanguages implements Action {
  readonly type: LanguageActionTypes = LanguageActionTypes.GET_LANGUAGES;
  constructor(public payload: IGetAllLanguagesPayload) { }
}

export class GetLanguagesSuccess implements Action {
  readonly type: LanguageActionTypes = LanguageActionTypes.GET_LANGUAGES_SUCCESS;
  constructor(public payload: ILanguagesWithTotalCount) { }
}

export class GetLanguagesFailure implements Action {
  readonly type: LanguageActionTypes = LanguageActionTypes.GET_LANGUAGES_FAILURE;
  constructor(public payload: any) { }
}
// End Get Languages

// Start Upsert Language
export class UpsertLanguage implements Action {
  readonly type: LanguageActionTypes = LanguageActionTypes.UPSERT_LANGUAGE;
  constructor(public payload: ILanguage) { }
}

export class UpsertLanguageComplete implements Action {
  readonly type: LanguageActionTypes = LanguageActionTypes.UPSERT_LANGUAGE_COMPLETE;
  constructor(public payload: any = null) { }
}
// End Upsert Language
export class ClearLanguageStore implements Action {
  readonly type: LanguageActionTypes = LanguageActionTypes.CLEAR_LANGUAGE_STORE;
  constructor(public payload: any = null) { }
}

// Start Delete Language
export class DeleteLanguage implements Action {
  readonly type: LanguageActionTypes = LanguageActionTypes.DELETE_LANGUAGE;
  constructor(public payload: IDeleteLanguagePayload) { }
}

export class DeleteLanguageComplete implements Action {
  readonly type: LanguageActionTypes = LanguageActionTypes.DELETE_LANGUAGE_COMPLETE;
  constructor(public payload: any) { }
}
// End Delete Language

// Union type for Language actions
export type LanguageActions =
| GetLanguages
| GetLanguagesSuccess
| GetLanguagesFailure
| ClearLanguageStore
| UpsertLanguage
| UpsertLanguageComplete
| DeleteLanguage
| DeleteLanguageComplete
;
