<div class="translations-container">
  <div class="search-bar" nz-row>
    <div nz-col nzSpan="10">
      <button nz-button nzType="default" nzSize="large" (click)="showPublishTranslationsModal()">
        {{ 'translations.btn.publishTranslations' | translate }}
      </button>
      <button nz-button nzType="primary" nzSize="large" (click)="openCreateUpdateForm()">
        {{ 'translations.btn.addLabel' | translate }}
      </button>
      <lms-upload-component></lms-upload-component>
    </div>
    <div nz-col nzSpan="8" nzOffset="6">
      <form [formGroup]="searchForm">
        <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
          <input
            type="text"
            nz-input
            formControlName="searchInput"
            nzShape="round"
            placeholder="{{ 'translations.placeholders.search' | translate }}"
          />
        </nz-input-group>
        <ng-template #suffixButton>
          <button
            nz-button
            nzType="primary"
            nzSize="large"
            nzShape="round"
            nzSearch
            nzLoading="false"
            (click)="handleSearch()"
          >
            {{ 'translations.btn.search' | translate }}
          </button>
        </ng-template>
      </form>
    </div>
  </div>
  <nz-card>
    <nz-table
      #basicTable
      nzBordered
      nzShowPagination="false"
      [nzData]="dataSource"
      [nzPageSize]="pageSize"
      [nzScroll]="{ x: '2000px', y: '650px' }"
    >
      <thead>
        <tr>
          <th nzLeft nzWidth="15%">
            {{ 'translations.label.label' | translate }}
            <nz-filter-trigger
              [(nzVisible)]="isFilterMenuVisible"
              [nzActive]="true"
              [nzDropdownMenu]="menu"
            >
              <span
                nz-tooltip
                [nzTooltipTitle]="'labels.btn.filter' | translate "
                nz-icon
                nzType="filter"
                nzTheme="outline"
                nzSize="large"
              ></span>
            </nz-filter-trigger>
          </th>
          <ng-container *ngFor="let lang of langList">
            <th nzWidth="{75% / columns.length}">{{lang.name}}</th>
          </ng-container>
          <th nzRight nzWidth="10%">{{ 'translations.label.actions' | translate }}</th>
        </tr>
      </thead>
      <tbody class="translations-table-body">
        <tr *ngFor="let row of basicTable.data" class="editable-row">
          <td nzLeft>
            <div
              class="editable-cell"
              [hidden]="labelId === row.id"
              (click)="startEditLabel(row)"
              nz-tooltip
              [nzTooltipTitle]="descriptionTpl"
              [nzTooltipOverlayStyle]="descriptionTp"
              nzTooltipColor="#90a4ae"
              [nzTooltipTrigger]="!row.description ? null : 'hover'"
            >
              {{ row?.label}}
            </div>
            <form [formGroup]="labelForm" [hidden]="labelId !== row.id">
              <input
                class="label-control"
                nz-input
                type="text"
                formControlName="label"
                (blur)="stopEditing()"
                (keydown.enter)="confirmEditLabel()"
                (keydown.escape)="stopEditing()"
                nz-tooltip
                [nzTooltipTitle]="descriptionTpl"
                [nzTooltipOverlayStyle]="descriptionTp"
                nzTooltipColor="#90a4ae"
                [nzTooltipTrigger]="!row.description ? null : 'hover'"
                [pattern]="labelPattern"
              />
              <!-- (keydown.tab)="startEditTranslation(langList[0], row)" -->
              <div *ngIf="isFormInvalid(labelForm, 'label')" class="alert">
                <span *ngIf="labelForm.get('label')?.errors?.['required']">
                  {{ 'common.msg.requiredInput' | translate }}
                </span>
                <span *ngIf="labelForm.get('label')?.errors?.['pattern']">
                  {{ 'labels.msg.labelPattern' | translate }}
                </span>
              </div>
            </form>
            <ng-template #descriptionTpl>
              <markdown class="label-description" [data]="row?.description ?? ''"></markdown>
              <!-- <span>{{row?.description ?? ''}}</span> -->
            </ng-template>
          </td>
          <ng-container *ngFor="let lang of langList">
            <td [ngSwitch]="!getCellValueInRow(lang, row)">
              <div
                class="editable-cell"
                [hidden]="rowId === row.id && columnId === lang.id"
                (click)="startEditTranslation(lang, row)"
              >
                <span *ngSwitchCase="false"> {{getCellValueInRow(lang, row)}}</span>
                <span
                  *ngSwitchCase="true"
                  nz-icon
                  nzType="warning"
                  [nzTheme]="'twotone'"
                  [nzTwotoneColor]="'#ff6600'"
                ></span>
                <span *ngSwitchCase="true" style="color: #ff6600">
                  {{ 'translations.placeholders.missingTranslation' | translate }}
                </span>
              </div>
              <form
                [formGroup]="translationForm"
                [hidden]="rowId !== row.id || columnId !== lang.id"
              >
                <input
                  type="text"
                  nz-input
                  formControlName="translateValue"
                  value="{{getCellValueInRow(lang, row)}}"
                  (blur)="stopEditing()"
                  (keydown.enter)="confirmEditTranslation(lang)"
                  (keydown.escape)="stopEditing()"
                />
                <!-- (keydown.tab)="startEditNextInput(lang, row)" -->
                <div *ngIf="isFormInvalid(translationForm, 'translateValue')" class="alert">
                  <span *ngIf="translationForm.get('translateValue')?.errors?.['required']">
                    {{ 'common.msg.requiredInput' | translate }}
                  </span>
                </div>
              </form>
            </td>
          </ng-container>
          <td nzRight class="actions">
            <button
              nz-tooltip
              [nzTooltipTitle]="'translations.label.updateLabel' | translate"
              nzShape="circle"
              nz-button
              nzType="default"
              (click)="openCreateUpdateForm(row)"
            >
              <span nz-icon nzType="edit" nzTheme="outline"></span>
            </button>
            <lms-popup-confirm
              nz-tooltip
              [nzTooltipTitle]="'translations.label.delete' | translate"
              [isDeleteTranslations]="true"
              [labelIds]="getId(row)"
            >
            </lms-popup-confirm>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-dropdown-menu #menu="nzDropdownMenu" nzClickHide="true">
      <ul class="filter-dropdown-menu" nz-menu>
        <form [formGroup]="filterForm">
          <nz-radio-group formControlName="radio">
            <div nz-row>
              <label nz-row nz-radio nzValue="all">{{ 'common.all' | translate }}</label>
            </div>
            <div nz-row>
              <label nz-row nz-radio nzValue="active">{{ 'common.active' | translate }}</label>
            </div>
            <div nz-row>
              <label nz-row nz-radio nzValue="inactive">{{ 'common.inactive' | translate }}</label>
            </div>
          </nz-radio-group>
        </form>
        <nz-divider></nz-divider>
        <button
          nz-button
          nzType="primary"
          nzSize="small"
          (click)="applyLanguageFilter()"
          >
          {{ 'translations.label.apply' | translate }}
        </button>
      </ul>
    </nz-dropdown-menu>
    <div class="translations-footer">
      <nz-pagination
        nzShowSizeChanger
        [nzPageIndex]="pageIndex"
        [nzTotal]="totalTranslations"
        [nzPageSize]="pageSize"
        [nzShowTotal]="rangeTemplate"
        [nzPageSizeOptions]="[10, 30, 50]"
        [nzHideOnSinglePage]="true"
        (nzPageIndexChange)="handlePageIndexChanged($event)"
        (nzPageSizeChange)="handlePageSizeChanged($event)"
      >
      </nz-pagination>
      <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} {{ 'common.pagination.of' | translate }} {{ total }}
        {{'common.pagination.items' | translate }}
      </ng-template>
    </div>
  </nz-card>
</div>
