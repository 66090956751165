import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ConfigActionTypes, GetLocalesFailure, GetLocalesSuccess } from "@actions/config.actions";
import { catchError, of, switchMap } from "rxjs";

import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocaleService } from "@services/apis/locale.service";
import { NotificationService } from "@services/utils/notification.service";
import { ParseHttpErrorRes } from '@utils/error-msg-parser';

@Injectable()
export class ConfigEffects {

  constructor(
    private actions$: Actions,
    private localeService: LocaleService,
    private notificationService: NotificationService,
  ) { }

  GetLocales$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConfigActionTypes.GET_LOCALES),
      switchMap(() => {
        return this
          .localeService
          .getAllLocales()
          .pipe(
            switchMap((res) => {
              return of(new GetLocalesSuccess(res));
            }),
            catchError((res: HttpErrorResponse) => {
              this
                .notificationService
                .error(
                  'locales.msg.loadFailure', {
                  err: ParseHttpErrorRes(res)
                });

              return of(new GetLocalesFailure(res));
            })
          )
      })
    )
  }
  );
}
