import * as LabelActions from '@actions/label.actions';

import { Component } from '@angular/core';
import { FileType } from '@models/enum/file-type.enum';
import { IBaseState } from '@states/base.states';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Store } from '@ngrx/store';

@Component({
  selector: 'lms-upload-component',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {
  constructor(
    private store: Store<IBaseState>,
    private modal: NzModalService,
  ) {}

  public fileList: NzUploadFile[] = [];

  public handleUpload(file: File): void {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      this.store.dispatch(
        new LabelActions.ImportTranslations({
          translations: fileReader.result as string,
          type: file.type as string,
        }),
      );
      this.fileList = [];
    };
    fileReader.readAsText(file as any);
  }

  public exportAsCsv(): void {
    this
      .store
      .dispatch(new LabelActions.ExportTranslations({
        type: FileType.CSV
      }));
  }

  public exportAsJson(): void {
    this
      .store.dispatch(new LabelActions.ExportTranslations({
        type: FileType.JSON
      }));
  }

  public onFileSelected(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      let file = fileList[0];
      this.modal.confirm({
      nzTitle: `Do you want to upload ${file.name}?`,
      nzOnOk: () => {
        this.handleUpload(file);
      },
    });
    }
  }
}
