import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()
export class NotificationService {

  constructor(private translateService: TranslateService,
              private notification: NzNotificationService) { }

  /**
   * Show success message
   * @method success
   */
  success(msgKey: string, msgData: any = {}): void {
    this.notification.success(
      this
        .translateService
        .instant('common.label.success'),
      this
        .translateService
        .instant(msgKey, msgData)
    );
  }

  /**
   * Show error message
   * @method error
   */
  error(msgKey: string, msgData: any = {}): void {
    this.notification.error(
      this
        .translateService
        .instant('common.label.failure'),
        this
        .translateService
        .instant(msgKey, msgData)
    );
  }
}
