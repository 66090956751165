import * as ConfigActions from '@actions/config.actions';
import * as LabelActions from '@actions/label.actions';
import * as LanguageActions from '@actions/language.actions';

import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  delay,
  map,
  takeUntil
} from 'rxjs';
import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  Event,
  NavigationEnd,
  Router
} from '@angular/router';

import { AppConfigurationService } from '@services/initializers/app-configuration.service';
import { ConfigStateSelectors } from '@reducers/config.reducers';
import { IBaseState } from '@states/base.states';
import { LabelStateSelectors } from '@reducers/label.reducers';
import { LanguageStateSelectors } from '@reducers/language.reducers';
import { PagePath } from '@models/enum/path.enum';
import { Store } from '@ngrx/store';

@Component({
  selector: 'lms-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  private unsubscribe$: ReplaySubject<any> = new ReplaySubject(1);

  loading$: Observable<boolean> = new BehaviorSubject<boolean>(false);
  public isCollapsed = false;
  public isTranslationSelected: boolean = false;
  public isLanguageSelected: boolean = false;
  public isPlatformSelected: boolean = false;

  constructor(
    private configService: AppConfigurationService,
    private store: Store<IBaseState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new ConfigActions.GetLocales());
    this.initLoading();
    this.setSelectedTabForSideBar();
  }

  getBackOfficeUrl(): string {
    return this.configService.backOfficeRedirectUrl;
  }

  ngOnDestroy(): void {
    // clear store
    this.store.dispatch(new ConfigActions.ClearConfigStore());
    this.store.dispatch(new LanguageActions.ClearLanguageStore());
    this.store.dispatch(new LabelActions.ClearLabelStore());

    // stop subscriber
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private initLoading(): void {
    this.loading$ = combineLatest([
      this.store.select(LanguageStateSelectors.getLoading),
      this.store.select(LabelStateSelectors.getLoading),
      this.store.select(ConfigStateSelectors.getLoading),
    ])
    .pipe(
      takeUntil(this.unsubscribe$),
      delay(0),
      map(
        (
          [
            langLoading,
            labelLoading,
            configLoading
          ]:
          [
            Array<LanguageActions.LanguageActionTypes>,
            Array<LabelActions.LabelActionTypes>,
            Array<ConfigActions.ConfigActionTypes>
          ]
      ) => {
        return [...langLoading, ...labelLoading, ...configLoading].length > 0;
      })
    );
  }

  private setSelectedTabForSideBar(): void {
    this.router.events.subscribe((event: Event) => {
       if (event instanceof NavigationEnd) {
         const currentRoute = event.url.slice(1);
         switch (currentRoute) {
           case PagePath.Languages:
             this.isLanguageSelected = true;
             break;
           case PagePath.Translations:
             this.isTranslationSelected = true;
             break;
         }
        }
    })
  }
}
