import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from '@pages/error/error.component';
import { ErrorLayoutComponent } from '@components/error-layout/error-layout.component';
import { LanguagesComponent } from '@pages/languages/languages.page';
import { LayoutComponent } from '@components/layout/layout.component';
import { MsalGuard } from '@azure/msal-angular';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from '@pages/notfound/notfound.component';
import { PagePath } from '@models/enum/path.enum';
import { TranslationsComponent } from '@pages/translations/translations.page';

const routes: Routes = [
  // Main App Pages
  {
    path: '',
    component: LayoutComponent,
    canActivate: [ MsalGuard ],
    children: [
      {
        path: '',
        redirectTo: PagePath.Translations,
        pathMatch: 'full'
      },
      {
        path: PagePath.Translations,
        pathMatch: 'full',
        component: TranslationsComponent,
        data: {
          breadcrumbI18nKey: "Translations"
        }
      },
      {
        path: PagePath.Languages,
        pathMatch: 'full',
        component: LanguagesComponent,
        data: {
          breadcrumbI18nKey: "Languages"
        }
      },
    ]
  },
  // Error Pages
  {
    path: 'error',
    component: ErrorLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ErrorComponent,
        canActivate: []
      },
      {
        path: 'notfound',
        pathMatch: 'full',
        component: NotFoundComponent,
        canActivate: []
      }
    ]
  },
  // wildcard routing
  {
    path: '**',
    redirectTo: ''
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
