import {
  IDeleteLabelPayload,
  IExportTranslationsPayload,
  IGetAllLabelsPayload,
  IImportTranslationsPayload,
  IUpsertLabelPayload
} from '@models/api/label.api.interface';

import { Action } from '@ngrx/store';
import { ILabelWithTotalCount } from '@models/label/label-with-total-count.interface';

export enum LabelActionTypes {
  // Get Labels
  GET_LABELS = 'GET_LABELS',
  GET_LABELS_SUCCESS = 'GET_LABELS_SUCCESS',
  GET_LABELS_FAILURE = 'GET_LABELS_FAILURE',

  // Upsert Label
  UPSERT_LABEL = 'UPSERT_LABEL',
  UPSERT_LABEL_COMPLETE = 'UPSERT_LABEL_COMPLETE',

  // Delete Label
  DELETE_LABEL = 'DELETE_LABEL',
  DELETE_LABEL_COMPLETE = 'DELETE_LABEL_COMPLETE',

  // Publish Translations
  PUBLISH_LABELS = 'PUBLISH_LABELS',
  PUBLISH_LABELS_COMPLETE = 'PUBLISH_LABELS_COMPLETE',

  // Export Translations
  EXPORT_LABELS = 'EXPORT_LABELS',
  EXPORT_LABELS_COMPLETE = 'EXPORT_LABELS_COMPLETE',

  // Import Translations
  IMPORT_LABELS = 'IMPORT_LABELS',
  IMPORT_LABELS_COMPLETE = 'IMPORT_LABELS_COMPLETE',

  // Common
  CLEAR_LABEL_STORE = 'CLEAR_LABEL_STORE',
}

// Start Get Labels
export class GetLabels implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.GET_LABELS;
  constructor(public payload: IGetAllLabelsPayload) { }
}

export class GetLabelsSuccess implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.GET_LABELS_SUCCESS;
  constructor(public payload: ILabelWithTotalCount) { }
}

export class GetLabelsFailure implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.GET_LABELS_FAILURE;
  constructor(public payload: any) { }
}
// End Get Labels

// Start Upsert Label
export class UpsertLabel implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.UPSERT_LABEL;
  constructor(public payload: IUpsertLabelPayload) { }
}

export class UpsertLabelComplete implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.UPSERT_LABEL_COMPLETE;
  constructor(public payload: any = null) { }
}
// End Upsert Label
export class ClearLabelStore implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.CLEAR_LABEL_STORE;
  constructor(public payload: any = null) { }
}

// Start Delete Label
export class DeleteLabel implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.DELETE_LABEL;
  constructor(public payload: IDeleteLabelPayload) { }
}

export class DeleteLabelComplete implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.DELETE_LABEL_COMPLETE;
  constructor(public payload: any) { }
}
// End Delete Label

// Start Publish Translations
export class PublishTranslations implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.PUBLISH_LABELS;
  constructor(public payload: (string | undefined) []) { }
}

export class PublishTranslationsComplete implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.PUBLISH_LABELS_COMPLETE;
  constructor(public payload: any = null) { }
}
// End Publish Translations

// Start Export Translations
export class ExportTranslations implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.EXPORT_LABELS;
  constructor(public payload: IExportTranslationsPayload) { }
}

export class ExportTranslationsComplete implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.EXPORT_LABELS_COMPLETE;
  constructor(public payload: any = null) { }
}
// End Export Translations

// Start Import Translations
export class ImportTranslations implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.IMPORT_LABELS;
  constructor(public payload: IImportTranslationsPayload) { }
}

export class ImportTranslationsComplete implements Action {
  readonly type: LabelActionTypes = LabelActionTypes.IMPORT_LABELS_COMPLETE;
  constructor(public payload: any = null) { }
}

// Union type for Label actions
export type LabelActions =
| GetLabels
| GetLabelsSuccess
| GetLabelsFailure
| ClearLabelStore
| UpsertLabel
| UpsertLabelComplete
| DeleteLabel
| DeleteLabelComplete
| PublishTranslations
| PublishTranslationsComplete
| ExportTranslations
| ExportTranslationsComplete
| ImportTranslations
| ImportTranslationsComplete
;
