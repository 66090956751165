import * as LabelActions from '@actions/label.actions';

import { Component, Input, OnInit } from '@angular/core';

import { IBaseState } from '@states/base.states';
import { ILanguage } from '@models/language/language.interface';
import { ILanguageWithCheck } from '@models/language/language-with-check.interface';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Store } from '@ngrx/store';

@Component({
  selector: 'lms-translation-publish',
  templateUrl: './translation-publish.component.html',
  styleUrls: ['./translation-publish.component.scss']
})

export class PublishTranslationComponent implements OnInit{
  public allChecked = false;
  public languagesWithCheck: ILanguageWithCheck[] = [];
  @Input() languages: ILanguage[] = [];

  public constructor(
    private modal: NzModalRef,
    private store: Store<IBaseState>,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.languagesWithCheck = this
      .languages
      .map((lang) => {
        return {
          language : lang,
          checked: lang.is_active ? true : false,
        };
      })

    this.allChecked = !this.isInactiveLang();
  }

  public checkAll(value: boolean): void {
    this
      .languagesWithCheck
      .forEach(lang => {
        lang.checked = value;
      });
  }

  public onItemChecked(): void {
    if (this.allChecked && this.languagesWithCheck.length != this.filterChecked(this.languagesWithCheck).length) {
      this.allChecked = false;
    }
    else if (!this.allChecked && this.languagesWithCheck.length == this.filterChecked(this.languagesWithCheck).length) {
      this.allChecked = true;
    }
  }

  public filterChecked(languagesWithCheck: ILanguageWithCheck[]): ILanguageWithCheck[] {
    return languagesWithCheck.filter(lang => {
      return lang.checked === true;
    })
  }

  public publishTranslations(languagesWithCheck: ILanguageWithCheck[]): void {
    const ids = this
      .filterChecked(languagesWithCheck)
      .map(lang => {
        return lang.language.id
      })

    this.store.dispatch(new LabelActions.PublishTranslations(ids));
  }

  public submit(){
    this.publishTranslations(this.languagesWithCheck);
    this.close();
  }

  public close(){
    this.modal.destroy();
  }

  private isInactiveLang(): boolean {
    return this
      .languagesWithCheck
      .some((lang) => lang.checked === false)
  }

}
