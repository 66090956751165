<div class="bar" nz-row>
  <div class="search-bar" nz-col nzSpan="8">
    <button nz-button nzType="primary" nzSize="large" (click)="openCreateUpdateForm()">
      {{ 'languages.btn.addLanguage' | translate }}
    </button>
  </div>
  <div nz-col nzSpan="8" nzOffset="8">
    <form [formGroup]="searchForm">
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input
          type="text"
          nz-input
          formControlName="searchInput"
          nzShape="round"
          placeholder="{{ 'languages.placeholders.search' | translate }}"
        />
      </nz-input-group>
      <ng-template #suffixButton>
        <button
          nz-button
          nzType="primary"
          nzSize="large"
          nzShape="round"
          nzSearch
          nzLoading="false"
          (click)="handleSearch()"
        >
          {{ 'translations.btn.search' | translate }}
        </button>
      </ng-template>
    </form>
  </div>
</div>
<nz-card>
  <nz-table
    #basicTable
    nzBordered
    nzShowPagination="false"
    [nzData]="dataSource"
    [nzPageSize]="pageSize"
  >
    <thead>
      <tr>
        <th nzWidth="30%">{{ 'common.name' | translate }}</th>
        <th nzWidth="20%">{{ 'common.locale' | translate }}</th>
        <th nzWidth="20%">{{ 'common.isDefault' | translate }}</th>
        <th nzWidth="20%">{{ 'common.isActive' | translate }}</th>
        <th nzWidth="10%">{{ 'languages.label.actions' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{ data?.name || '' }}</td>
        <td>{{ data?.locale || '' }}</td>
        <td>{{ data?.is_default }}</td>
        <td>{{ data?.is_active }}</td>
        <td class="actions">
          <button
            nz-tooltip
            [nzTooltipTitle]="'languages.label.updateLanguage' | translate"
            nzShape="circle"
            nzShape="circle"
            nz-button
            nzType="default"
            (click)="openCreateUpdateForm(data)"
          >
            <span nz-icon nzType="edit" nzTheme="outline"></span>
          </button>
          <lms-popup-confirm
            nz-tooltip
            [nzTooltipTitle]="'languages.label.delete' | translate"
            [isDeleteLanguage]="true"
            [language]="data"
          ></lms-popup-confirm>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <div class="languages-footer">
    <nz-pagination
      nzShowSizeChanger
      [nzPageIndex]="pageIndex"
      [nzTotal]="totalLanguages"
      [nzPageSize]="pageSize"
      [nzShowTotal]="rangeTemplate"
      [nzPageSizeOptions]="[10, 30, 50]"
      [nzHideOnSinglePage]="true"
      (nzPageIndexChange)="handelPageIndexChanged($event)"
      (nzPageSizeChange)="handelPageSizeChanged($event)"
    >
    </nz-pagination>
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} {{ 'common.pagination.of' | translate }} {{ total }} {{
      'common.pagination.items' | translate }}
    </ng-template>
  </div>
</nz-card>
