import { ConfigActionTypes, ConfigActions } from '@actions/config.actions';

import { IBaseState } from '@states/base.states';
import { IConfigState } from "@states/config.states";
import { createSelector } from '@ngrx/store';

const initialState: IConfigState = {
  locales: [],
  currLang: 'en',
  user: {
    email: 'test@netcompany.com'
  },
  version: 'v0',
  loading: []
}

export function reducer(state: IConfigState = initialState, action: ConfigActions): IConfigState {
  switch (action.type) {
    // START Get User
    case ConfigActionTypes.GET_USER: {
      return {
        ...state,
        loading: [...state.loading, action.type]
      };
    }

    case ConfigActionTypes.GET_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: clearLoading(state, ConfigActionTypes.GET_USER)
      };
    }

    case ConfigActionTypes.GET_USER_FAILURE: {
      return {
        ...state,
        user: undefined,
        loading: clearLoading(state, ConfigActionTypes.GET_USER),
      };
    }

    // START Get Locales
    case ConfigActionTypes.GET_LOCALES: {
      return {
        ...state,
        loading: [...state.loading, action.type]
      };
    }

    case ConfigActionTypes.GET_LOCALES_SUCCESS: {
      return {
        ...state,
        locales: action.payload.locales,
        loading: clearLoading(state, ConfigActionTypes.GET_LOCALES)
      };
    }

    case ConfigActionTypes.GET_LOCALES_FAILURE: {
      return {
        ...state,
        locales: [],
        loading: clearLoading(state, ConfigActionTypes.GET_LOCALES),
      };
    }

    // END Get Configs

    case ConfigActionTypes.UPDATE_STORED_VERSION: {
      return {
        ...state,
        version: action.payload,
      };
    }

    case ConfigActionTypes.CLEAR_CONFIG_STORE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}


function clearLoading(state: IConfigState, action: ConfigActionTypes): Array<ConfigActionTypes> {
  return state.loading.filter((loadingMarker: ConfigActionTypes) => loadingMarker !== action);
}

const stateSelector = (state: IBaseState) => state.config;

export const ConfigStateSelectors = {
  locales: createSelector(stateSelector, (state: IConfigState) => state.locales),
  currLang: createSelector(stateSelector, (state: IConfigState) => state.currLang),
  user: createSelector(stateSelector, (state: IConfigState) => state.user),
  version: createSelector(stateSelector, (state: IConfigState) => state.version),
  getLoading: createSelector(stateSelector, (state: IConfigState) => state.loading),
};
