import { Component, Input } from '@angular/core';

@Component({
  selector: 'lms-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  @Input() size: 'small' | 'default' | 'big' | 'large' = 'default';
  @Input() message: string = 'common.msg.loading';

  getSizeClass(): string {
    switch (this.size) {
      case 'small':
        return 'size-sm';
      case 'big':
        return 'size-2x';
      case 'large':
        return 'size-3x';
      default:
        return '';
    }
  }
}
