import * as LanguageActions from '@actions/language.actions';

import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormControl,
  FormGroup
} from '@angular/forms';
import {
  ReplaySubject,
  takeUntil
} from 'rxjs';

import { FormAction } from '@models/enum/form-action.enum';
import { IBaseState } from '@states/base.states';
import { IGetAllLanguagesPayload } from '@models/api/language.api.interface';
import { ILanguage } from '@models/language/language.interface';
import { LanguageFormComponent } from '@components/language-form/language-form.component';
import { LanguageStateSelectors } from '@reducers/language.reducers';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Pagination } from '@models/enum/pagination.enum';
import { Store } from '@ngrx/store';

@Component({
  selector: 'lms-languages',
  templateUrl: './languages.page.html',
  styleUrls: ['./languages.page.scss'],
})

export class LanguagesComponent implements OnInit, OnDestroy {
  public dataSource: ILanguage[] = [];
  public skip: number = Pagination.skip;
  public pageSize: number = Pagination.pageSize;
  public pageIndex: number = Pagination.pageIndexDefault;
  public totalLanguages: number = Pagination.totalCountDefault;
  public searchText: string = '';

  public searchForm: FormGroup = new FormGroup({
    searchInput: new FormControl(''),
  });

  private readonly unsubscribe$: ReplaySubject<number> = new ReplaySubject(1);

  public constructor(
    private readonly modalService: NzModalService,
    private readonly store: Store<IBaseState>,
    private readonly cdRef: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.getLanguages();
    this.languagesSubscriber();
    this.langsTotalSubscriber();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(0);
    this.unsubscribe$.complete();
  }

  public handleSearch(): void {
    this.searchText = this.searchForm.get('searchInput')?.value as string || '';
    this.skip = Pagination.skip;
    this.pageIndex = Pagination.pageIndexDefault;
    this.getLanguages();
  }

  public handelPageIndexChanged(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.skip = (this.pageIndex - 1) * this.pageSize;
    this.getLanguages();
  }

  public handelPageSizeChanged(pageSize: number): void {
    this.pageSize = pageSize;
    this.getLanguages();
  }

  public openCreateUpdateForm(language: ILanguage = {
    name: '',
    locale: '',
    is_default: false,
    is_active: false,
  }): void {
    this.modalService.create({
      nzContent: LanguageFormComponent,
      nzComponentParams: {
        mode: language?.name ? FormAction.update : FormAction.add,
        language,
      },
      nzWidth: 1000,
    });
  }

  private getLanguages(): void {
    const langPayload: IGetAllLanguagesPayload = {
      params: {
        pagination: {
          skip: this.skip ?? '',
          limit: this.pageSize ?? '',
        },
        contain: this.searchText ?? '',
        isActive: undefined,
      },
    };

    this.store.dispatch(new LanguageActions.GetLanguages(langPayload));
  }

  private languagesSubscriber(): void {
    this.store.select(LanguageStateSelectors.languages)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((langs: ILanguage[]) => {
        this.dataSource = langs
          .map((lang) => {
            return {
              id: lang.id,
              name: lang.name,
              locale: lang.locale,
              is_default: lang.is_default,
              is_active: lang.is_active,
            };
          });
        this.cdRef.detectChanges();
      });
  }

  private langsTotalSubscriber(): void {
    this.store.select(LanguageStateSelectors.totalLanguages)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((total: number) => {
        this.totalLanguages = total;
      });
  }
}
