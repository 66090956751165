import * as ConfigActions from '@actions/config.actions';

import {
  CompareVersion,
  GetVersionFromLocalStorage,
  ParseVersionToCorrectValueFormat
} from '@utils/version-parser';

import { DEFAULT_VERSION } from '@models/consts/common.const';
import { IBaseState } from '@states/base.states';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '@environments/environment';

interface IAuthStrategy {
  clientId: string,
  authority: string,
  redirectUri: string,
  scopes: string[]
}

const defaultAuthStrategy: IAuthStrategy = {
  clientId: '',
  authority: '',
  redirectUri: '',
  scopes: []
}


@Injectable({
  providedIn: 'root'
})
export class AppConfigurationService {
  readonly isProduction: boolean = environment['production'] ? environment['production'] : false;
  readonly defaultLang: string = 'en';
  readonly version: string = environment['version'] ? environment['version'] : DEFAULT_VERSION;
  readonly apiEndpoint: string = environment['apiEndpoint'] ? environment['apiEndpoint'] : '';
  readonly apiVersion: string = environment['apiVersion'] ? environment['apiVersion'] : 'v1';
  readonly backOfficeRedirectUrl: string = environment['backOfficeRedirectUrl'] ? environment['backOfficeRedirectUrl'] : '';
  readonly apimSubscriptionKey: string = environment['apimSubscriptionKey'] ? environment['apimSubscriptionKey'] : '';
  readonly sessionTimeOut: number = environment['sessionTimeOut'] ? environment['sessionTimeOut'] : 1800000;
  readonly authStrategy: IAuthStrategy = environment['authStrategy'] ? environment['authStrategy'] : defaultAuthStrategy;

  constructor(private store: Store<IBaseState>) {
    let currBrowserVersion = GetVersionFromLocalStorage();
    let currAppVersion = ParseVersionToCorrectValueFormat(this.version);

    if (CompareVersion(currAppVersion, currBrowserVersion) === 1) {
      // clear local storage when there is version update
      localStorage.clear();
      this.store.dispatch(new ConfigActions.UpdateStoredVersion(currAppVersion));
    }
  }
}
