import { DEFAULT_VERSION } from "@models/consts/common.const";

export function GetVersionFromLocalStorage (): string {
  let floatVersion = DEFAULT_VERSION;
  let storedVersion = localStorage.getItem('config');

  if (storedVersion ) {
    try {
      let v = JSON.parse(storedVersion)?.version ?? '';
      floatVersion = ParseVersionToCorrectValueFormat(v);
    } catch {
      floatVersion = DEFAULT_VERSION;
    }
  }

  return floatVersion;
}

// Make version to follow format {major}.{minor}.{bugfix}
export function ParseVersionToCorrectValueFormat(v: string): string {
  if (!v) return DEFAULT_VERSION;

  let [major, minor, bugfix] = [...v.split('.')];

  if (!major) return DEFAULT_VERSION;

  return `${major ?? 0}.${minor ?? 0}.${bugfix ?? 0}`;
}

// 0: version strings are equal
// 1: version a is greater than b
// -1: version b is greater than a
export function CompareVersion(a: string, b: string): number {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
}

