<div class="creation-form">
  <form nz-form [formGroup]="languageForm">
    <h1>
      {{
        (mode === 'ADD' ? 'languages.label.addLanguage' : 'languages.label.updateLanguage')
          | translate
      }}
    </h1>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired>
          {{ 'common.name' | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="18" nzHasFeedback [nzErrorTip]="localeErrorTpl">
          <nz-select
            nzPlaceHolder="{{ 'locales.placeholders.dropdown' | translate }}"
            nzAllowClear
            nzShowSearch
            formControlName="locale"
          >
            <ng-container *ngFor="let option of localeOptionsDataSource">
              <nz-option
                [nzLabel]="option.label"
                [nzValue]="option.value"
                [nzDisabled]="option.disable"
              ></nz-option>
            </ng-container>
          </nz-select>
          <ng-template #localeErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              {{ 'common.msg.requiredInput' | translate }}
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="4">
          {{ 'common.isDefault' | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="18" nzHasFeedback [nzErrorTip]="defaultErrorTpl">
          <label
            nz-checkbox
            formControlName="isDefault"
            (ngModelChange)="defaultChange($event)"
          ></label>
          <ng-container *ngIf="isDefaultCheckBoxTicked">
            <p class="disable-msg">
              {{ 'common.msg.disable' | translate : { lang: getDefaultLanguageName() } }}
            </p>
          </ng-container>
          <ng-template #defaultErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              {{ 'common.msg.default' | translate }}
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="4"> {{ 'common.isActive' | translate }}</nz-form-label>
        <nz-form-control [nzSpan]="18">
          <label
            nz-checkbox
            [nzDisabled]="languageForm.value.isDefault ? 'true' : 'false'"
            formControlName="isActive"
          ></label>
        </nz-form-control>
      </nz-form-item>
      <ng-template [nzModalFooter]>
        <button nz-button nzType="default" (click)="handleCancel()">
          {{ 'common.btn.cancel' | translate }}
        </button>
        <button
          nz-button
          nzType="primary"
          [disabled]="languageForm.invalid"
          (click)="handleSubmit()"
        >
          {{ 'common.btn.submit' | translate }}
        </button>
      </ng-template>
    </div>
  </form>
</div>
