import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

import { AppConfigurationService } from '@services/initializers/app-configuration.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagePath } from '@models/enum/path.enum';
import { Router } from '@angular/router';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    private configService: AppConfigurationService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // append custom headers
    request = request.clone({
      setHeaders: {
        'Ocp-Apim-Subscription-Key': this.configService.apimSubscriptionKey,
      }
    });

    // global error handling
    return next
      .handle(request)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // show Error Page.
          return this.router.navigate([PagePath.ErrorPage])
        })
      )
  }
}
