import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DeleteLanguageComplete, GetLanguages, GetLanguagesFailure, GetLanguagesSuccess, LanguageActionTypes, UpsertLanguageComplete } from '@actions/language.actions';
import { IDeleteLanguageDispatchedAction, IGetAllLanguagesDispatchedAction, IUpsertLanguageDispatchedAction } from '@models/api/language.api.interface';
import { catchError, switchMap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { LanguageService } from '@services/apis/language.service';
import { NotificationService } from '@services/utils/notification.service';
import { ParseHttpErrorRes } from '@utils/error-msg-parser';
import { of } from 'rxjs';

@Injectable()
export class LanguageEffects {

  constructor(
    private actions$: Actions,
    private languageService: LanguageService,
    private notificationService: NotificationService,
  ) { }

  GetLanguages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LanguageActionTypes.GET_LANGUAGES),
      switchMap((input: IGetAllLanguagesDispatchedAction) => {
        return this
          .languageService
          .getAllLanguages(input.payload)
          .pipe(
            switchMap((res) => {
              return of(new GetLanguagesSuccess(res));
            }),
            catchError((res: HttpErrorResponse) => {
              this
                .notificationService
                .error(
                  'languages.msg.loadFailure', {
                  err: ParseHttpErrorRes(res)
                });

              return of(new GetLanguagesFailure(res));
            })
          )

      })
    )}
  );

  UpsertLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LanguageActionTypes.UPSERT_LANGUAGE),
      switchMap((input: IUpsertLanguageDispatchedAction) => {
        return this
          .languageService
          .upsertLanguage(input.payload)
          .pipe(
            switchMap((_res) => {
              this
                .notificationService
                .success(
                  input?.payload?.id
                    ? 'languages.msg.updateSuccess'
                    : 'languages.msg.createSuccess');

              return [
                // clear loading
                new UpsertLanguageComplete(),
                // fetch languages
                new GetLanguages({}),
              ];
            }),
            catchError((res: any) => {
              this
                .notificationService
                .error(
                  ( input?.payload?.id
                    ? 'languages.msg.updateFailure'
                    : 'languages.msg.createFailure'
                  ), {
                    err: ParseHttpErrorRes(res)
                  });

              return [
                // clear loading
                new UpsertLanguageComplete(),
              ];
            })
          )
      })
    )
  });

  DeleteLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LanguageActionTypes.DELETE_LANGUAGE),
      switchMap((input: IDeleteLanguageDispatchedAction) => {
        return this
          .languageService
          .deleteLanguage(input.payload)
          .pipe(
            switchMap((res) => {
              this
                .notificationService
                .success('languages.msg.deleteSuccess');

              return [
                // clear loading
                new DeleteLanguageComplete(res),
                // fetch languages
                new GetLanguages({}),
              ];
            }),
            catchError((res: any) => {
              this
                .notificationService
                .error(
                  'languages.msg.deleteFailure', {
                  err: ParseHttpErrorRes(res)
                });

              return of(new DeleteLanguageComplete(res));
            })
          )
      })
    )
  }
  );

}
