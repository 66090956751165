<div nz-row class="lms-header-container">
  <div nz-col nzSpan="8">
    <nz-page-header-title>
      <a href="/">
        {{ 'common.title' | translate }}
      </a>
    </nz-page-header-title>
  </div>
  <div *ngIf="user" class="lms-user-container" nz-col nzSpan="16">
    <div class="lms-user" nz-dropdown [nzDropdownMenu]="menu">
      <span nz-icon nzType="user" nzTheme="outline"></span>
      <span>{{ user.email || '' }}</span>
    </div>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul class="user-dropdown-menu" nz-menu nzSelectable>
      <li (click)="logout()" nz-menu-item>
        <div nz-row>
          <div nz-col nzSpan="8">
            <span nz-icon nzType="logout" nzTheme="outline"></span>
          </div>
          <div nz-col nzSpan="16">{{ 'common.label.logout' | translate }}</div>
        </div>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
