import * as saveAs from "file-saver";

import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  DeleteLabelComplete,
  ExportTranslationsComplete,
  GetLabels,
  GetLabelsFailure,
  GetLabelsSuccess,
  ImportTranslationsComplete,
  LabelActionTypes,
  PublishTranslationsComplete,
  UpsertLabelComplete
} from '@actions/label.actions';
import {
  IDeleteLabelDispatchedAction,
  IExportTranslationsDispatchedAction,
  IGetAllLabelsDispatchedAction,
  IImportTranslationsDispatchedAction,
  IPublishTranslationsDispatchedAction,
  IUpsertLabelDispatchedAction
} from '@models/api/label.api.interface';
import {
  catchError,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { IBaseState } from "@states/base.states";
import { ILanguage } from "@models/language/language.interface";
import { Injectable } from "@angular/core";
import { LabelService } from '@services/apis/label.service';
import { LanguageStateSelectors } from "@reducers/language.reducers";
import { NotificationService } from '@services/utils/notification.service';
import { ParseHttpErrorRes } from '@utils/error-msg-parser';
import { Store } from "@ngrx/store";
import { formatDate } from "@angular/common";
import { of } from 'rxjs';

@Injectable()
export class LabelEffects {

  constructor(
    private actions$: Actions,
    private store: Store<IBaseState>,
    private labelService: LabelService,
    private notificationService: NotificationService,
  ) { }

  GetLabels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LabelActionTypes.GET_LABELS),
      switchMap((input: IGetAllLabelsDispatchedAction) => {
        return this
          .labelService
          .getAllLabels(input.payload)
          .pipe(
            switchMap((res) => {
              return of(new GetLabelsSuccess(res));
            }),
            catchError((res: HttpErrorResponse) => {
              this
                .notificationService
                .error(
                  'translations.msg.loadFailure', {
                  err: ParseHttpErrorRes(res)
                });

              return of(new GetLabelsFailure(res));
            })
          )

      })
    )}
  );

  UpsertLabel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LabelActionTypes.UPSERT_LABEL),
      switchMap((input: IUpsertLabelDispatchedAction) => {
        return this
          .labelService
          .upsertLabels(input.payload.labels)
          .pipe(
            switchMap((_res) => {
              this
                .notificationService
                .success(
                  input?.payload
                    ? 'translations.msg.updateSuccess'
                    : 'translations.msg.createSuccess');

              return [
                // clear loading
                new UpsertLabelComplete(),
                // fetch labels
                new GetLabels(input.payload.params ?? {}),
              ];
            }),
            catchError((res: any) => {
              this
                .notificationService
                .error(
                  ( input?.payload
                    ? 'translations.msg.updateFailure'
                    : 'translations.msg.createFailure'
                  ), {
                    err: ParseHttpErrorRes(res)
                  });

              return [
                // clear loading
                new UpsertLabelComplete(),
              ];
            })
          )
      })
    )
  });

  DeleteLabel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LabelActionTypes.DELETE_LABEL),
      switchMap((input: IDeleteLabelDispatchedAction) => {
        return this
          .labelService
          .deleteLabel(input.payload)
          .pipe(
            switchMap((res) => {
              this
                .notificationService
                .success('translations.msg.deleteSuccess');

              return [
                // clear loading
                new DeleteLabelComplete(res),
                // fetch labels
                new GetLabels(input.payload.params ?? {}),
              ];
            }),
            catchError((res: any) => {
              this
                .notificationService
                .error(
                  'translations.msg.deleteFailure', {
                  err: ParseHttpErrorRes(res)
                });

              return of(new DeleteLabelComplete(res));
            })
          )
      })
    )
  });

  PublishTranslation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LabelActionTypes.PUBLISH_LABELS),
      switchMap((input: IPublishTranslationsDispatchedAction) => {
        return this
          .labelService
          .publishTranslations(input.payload)
          .pipe(
            switchMap((_res) => {
              this
                .notificationService
                .success('translations.msg.publishSuccess');

              return of(new PublishTranslationsComplete());
            }),
            catchError((res: any) => {
              this
                .notificationService
                .error(
                  'translations.msg.publishFailure', {
                    err: ParseHttpErrorRes(res)
                  });

              return of(new PublishTranslationsComplete());
            })
          )
      })
    )
  })

  ExportTranslation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LabelActionTypes.EXPORT_LABELS),
      switchMap((input: IExportTranslationsDispatchedAction) => {
        return this
          .labelService
          .exportTranslations(input.payload)
          .pipe(
            switchMap((_res) => {
              this
                .notificationService
                .success('translations.msg.exportSuccess');

                const today = formatDate(new Date(), 'dd_MM_YYYY', 'en_US');

                if (input.payload.type === 'text/csv') {
                  const blob = new Blob(["\ufeff", _res], { type: "text/csv" });
                  saveAs(blob, `translations_${today}.csv`);
                } else {
                  saveAs(_res, `translations_${today}.json`);
                }

              return of(new ExportTranslationsComplete());
            }),
            catchError((res: any) => {
              this
                .notificationService
                .error(
                  'translations.msg.exportFailure', {
                    err: ParseHttpErrorRes(res)
                  });

              return of(new ExportTranslationsComplete());
            })
          )
      })
    )
  })

  ImportTranslation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LabelActionTypes.IMPORT_LABELS),
      withLatestFrom(this.store.select(LanguageStateSelectors.languages)),
      switchMap(([input, languages]: [IImportTranslationsDispatchedAction, ILanguage[]]) => {
        return this
          .labelService
          .importTranslations(input.payload)
          .pipe(
            switchMap((_res) => {
              this
                .notificationService
                .success('translations.msg.importSuccess');

              return [
                // clear loading
                new ImportTranslationsComplete(),
                // fetch translations
                new GetLabels({}),
              ];
            }),
            catchError((res: any) => {
              this
                .notificationService
                .error(
                  'translations.msg.importFailure', {
                    err: ParseHttpErrorRes(res)
                  });

              return of(new ImportTranslationsComplete());
            })
          )
      })
    )
  })

}
